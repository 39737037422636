<template>
  <!-- 新增采购管理  新增销售管理   编辑采购管理  编辑销售管理 -->
  <div class="add-purchasing-page-box page-info-content">
    <!-- <h4 class="page-title">
      {{ pageTitle }}
    </h4> -->
    <!-- 上面要填的信息 -->
    <el-form
      ref="ruleFormRef"
      :model="formData"
      label-width="135px"
      label-position="left"
      class="form-item-row"
    >
      <el-form-item
        label="签订日期"
        prop="signDate"
        :rules="[...rules.selectiveType]"
      >
        <el-date-picker
          v-model="formData.signDate"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="dateOptions"
          placeholder="请选择签订日期"
        />
      </el-form-item>
      <el-form-item
        label="签订地点"
        prop="cityArr"
        :rules="[...rules.selectiveType]"
      >
        <el-cascader
          ref="cascader"
          v-model="formData.cityArr"
          :options="options"
          placeholder="请选择签订地点"
          :props="{ value: 'code', label: 'name' }"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item
        label="合同编号"
        prop="contractCode"
        :rules="[
          formPattern.numericLettersCharacter,
          { required: true, message: '请输入合同编号', trigger: 'change' },
        ]"
      >
        <el-input
          v-model="formData.contractCode"
          placeholder="请输入合同编号"
          :disabled="[2,4].includes(pageType)"
          :maxlength="30"
        />
      </el-form-item>
      <el-form-item
        label="税率(%)"
        prop="taxRate"
        :rules="[...rules.selectiveType]"
      >
        <el-select
          v-model="formData.taxRate"
          placeholder="请选择税率"
          @change="computationRule"
        >
          <el-option
            v-for="item in $store.getters.getDictionaryItem('TAX_RATE')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictName"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="采购方"
        prop="purchaseCmpId"
        :rules="[...rules.selectiveType]"
      >
        <span
          slot="label"
        >采购方
          <el-tooltip
            class="item"
            effect="dark"
            content="找不到企业？请前往交易企业里添加"
            placement="top"
          >
            <i class="el-icon-warning" /><!--小问号提示-->
          </el-tooltip>
        </span>
        <el-select
          v-model="formData.purchaseCmpId"
          reserve-keyword
          filterable
          placeholder="请选择采购方"
          @change="getProcuringName"
        >
          <el-option
            v-for="item in userArr"
            :key="item.id"
            :label="item.cmpName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="销售方"
        prop="saleCmpId"
        :rules="[...rules.selectiveType]"
      >
        <span
          slot="label"
        >销售方
          <el-tooltip
            class="item"
            effect="dark"
            content="找不到企业？请前往交易企业里添加"
            placement="top"
          >
            <i class="el-icon-warning" /><!--小问号提示-->
          </el-tooltip>
        </span>
        <el-select
          v-model="formData.saleCmpId"
          reserve-keyword
          filterable
          placeholder="请选择销售方"
          @change="getsellerName"
        >
          <el-option
            v-for="item in userArr"
            :key="item.id"
            :label="item.cmpName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="交(提)货截止"
        prop="deliveryExpirationDate"
        :rules="[...rules.selectiveType]"
      >
        <el-date-picker
          v-model="formData.deliveryExpirationDate"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="dateOptions"
          placeholder="请选择交(提)货截止"
        />
      </el-form-item>
      <el-form-item
        label="提货仓库"
        prop="deliveryWarehouse"
        :rules="[...rules.message]"
      >
        <el-input
          v-model="formData.deliveryWarehouse"
          placeholder="请输入提货仓库"
          :maxlength="20"
        />
      </el-form-item>
      <!-- 这个是销售管理才有的 -->
      <el-form-item
        v-if="[3, 4].includes(pageType)"
        label="关联采购合同编号"
      >
        <el-select
          v-model="formData.relationPurchanseContractCode"
          placeholder="请选择采购合同编号"
        >
          <el-option
            v-for="item in contractNumberArr"
            :key="item.id"
            :label="item.contractCode"
            :value="item.contractCode"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 商品明细 -->
    <div class="commodity-details-box">
      <h4 class="subtitle">
        商品明细
      </h4>
      <Table :item-data="itemData" :list-data="listdata">
        <!-- 品种 -->
        <template #firstGoodsCode="{ row }">
          <el-select
            v-model="row.firstGoodsCode"
            placeholder="请选择"
            @change="getGoods(row)"
          >
            <el-option
              v-for="item in goodsArr"
              :key="item.code"
              :label="item.goodsName"
              :value="item.code"
            />
          </el-select>
        </template>
        <!-- 货物名称 -->
        <template #goodsName="{ row, index }">
          <el-input
            v-model="row.goodsName"
            :maxlength="30"
            placeholder="请输入"
            @change="judgmentIdentical(row, index)"
          />
        </template>
        <!-- 品牌 -->
        <template #goodsBrand="{ row, index }">
          <el-input
            v-model="row.goodsBrand"
            :maxlength="15"
            placeholder="请输入"
            @change="judgmentIdentical(row, index)"
          />
        </template>
        <!-- 规格型号 -->
        <template #goodsMaterial="{ row, index }">
          <el-input
            v-model="row.goodsMaterial"
            :maxlength="15"
            placeholder="请输入"
            @change="judgmentIdentical(row, index)"
          />
        </template>
        <!-- 单位 -->
        <template #goodsUnit="{ row, index }">
          <el-select
            v-model="row.goodsUnit"
            placeholder="请选择"
            @change="judgmentIdentical(row, index)"
          >
            <el-option
              v-for="item in $store.getters.getDictionaryItem('purchaseUnit')"
              :key="item.dictId"
              :label="item.dictName"
              :value="item.dictId"
            />
          </el-select>
        </template>
        <!-- 数量 -->
        <template #goodsQuantity="{ row }">
          <el-input-number
            v-model="row.goodsQuantity"
            :precision="3"
            :controls="false"
            placeholder="请输入"
            :min="0"
            @change="computationRule(row)"
          />
        </template>
        <template #taxUnitPrice="{ row }">
          <!-- //单价 -->
          <el-input-number
            v-model="row.taxUnitPrice"
            :precision="2"
            :controls="false"
            placeholder="请输入"
            :min="0"
            @change="computationRule(row)"
          />
        </template>
        <template #operation="{ index }">
          <i class="add-icon el-icon-circle-plus-outline" @click="addList" />
          <i
            v-if="listdata.length > 1"
            class="delete-icon el-icon-remove-outline"
            @click="deleteList(index)"
          />
        </template>
      </Table>
      <div class="statistics-box">
        <div class="item">
          <span>数量合计</span>
          <p>{{ formData.totalQuantity || "-" }}</p>
        </div>
        <div class="item">
          <span>金额合计</span>
          <p>{{ formData.totalAmount || "-" }}</p>
        </div>
      </div>
      <div class="statistics-box">
        <div class="item">
          <span>金额大写</span>
          <p>{{ formData.totalAmountUppercase || "-" }}</p>
        </div>
        <div class="item">
          <span>备注</span>
          <el-input
            v-model="formData.remark"
            :maxlength="30"
            placeholder="请输入备注"
          />
        </div>
      </div>
    </div>
    <!-- 材料清单 -->
    <div class="commodity-details-box">
      <h4 class="subtitle">
        材料清单
      </h4>
      <el-table border :data="uploadData" style="width: 100%">
        <el-table-column type="index" width="50" />
        <el-table-column prop="documentName" label="材料名称" />
        <el-table-column prop="documentType" label="材料类型" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 1是未上传   4是上传失败   3是上传成功   2是上传中 -->
            <div v-if="scope.row.status === 1" class="havenUploaded">
              <el-upload
                class="upload-demo"
                :action="$baseUpload"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
                :before-upload="beforeUpload"
              >
                <img
                  src="@/assets/image/file_icon.png"
                  alt="loading"
                  @click="getUploadContent(scope.row, scope.$index)"
                >
                <el-button
                  link
                  type="text"
                  size="small"
                  @click="getUploadContent(scope.row, scope.$index)"
                >
                  上传文件
                </el-button>
              </el-upload>
            </div>
            <!-- 上传中 -->
            <div v-else-if="scope.row.status === 2" class="havenUploaded">
              <img src="@/assets/image/loading.png" alt="loading">
              <span class="name color elp-1">{{ scope.row.fileName }}</span>
              <el-button
                link
                type="text"
                size="small"
                @click="deleteUploadFile(scope.row, scope.$index)"
              >
                取消
              </el-button>
            </div>
            <!-- 上传失败 -->
            <div v-else-if="scope.row.status === 4" class="havenUploaded">
              <img src="@/assets/image/delete1.png" alt="">
              <span class="name elp-1">{{ scope.row.fileName }}</span>
              <el-button
                link
                type="text"
                size="small"
                @click="deleteUploadFile(scope.row, scope.$index)"
              >
                取消
              </el-button>
              <el-upload
                class="upload-demo"
                :action="$baseUpload"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
                :before-upload="beforeUpload"
              >
                <el-button
                  link
                  type="text"
                  size="small"
                  @click="getUploadContent(scope.row, scope.$index)"
                >
                  重新上传
                </el-button>
              </el-upload>
            </div>
            <!-- 上传成功 -->
            <div v-else-if="scope.row.status === 3" class="havenUploaded">
              <img
                v-if="scope.row.type === 'pdf'"
                src="@/assets/image/fileType1.png"
                alt=""
              >
              <img v-else :src="$fileUrl + scope.row.materialUrl" alt="">
              <span class="name elp-1">{{ scope.row.fileName }}</span>
              <!-- 除了图片之外都不可以查看了 -->
              <a :href="$fileUrl + scope.row.materialUrl" target="_blank">
                <el-button link type="text" size="small"> 查看 </el-button>
              </a>
              <el-button
                link
                type="text"
                size="small"
                @click.prevent="downloadAllFile(scope.row)"
              >
                下载
              </el-button>
              <el-button
                link
                type="text"
                size="small"
                @click="deleteUploadFile(scope.row, scope.$index)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="button-assembly-box">
        <el-button size="small" @click="back">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="submit">
          提交
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table'
import { getSelectAreaTree } from '@/api/login'
import { downloadFile2, numberDX2, roundUpToInteger } from '@/utils/util'
import { formPattern, rules } from '@/utils/matchFormRegex'
import { updateExchangeInfo, addExchangeInfo, getTransactionCmpInfo, getExchangeDetail, detailExchangeInfo } from '@/api/transactionData'
import {
  getChildGoodsList
} from '@/api/goods'
export default {
  components: { Table },
  data() {
    return {
      formPattern,
      rules,
      goodsArr: [],
      itemData: [
        { label: '品种', prop: 'firstGoodsCode', required: true },
        { label: '货物名称', prop: 'goodsName', required: true },
        { label: '品牌', prop: 'goodsBrand' },
        { label: '型号规格', prop: 'goodsMaterial' },
        { label: '单位', prop: 'goodsUnit', required: true },
        { label: '数量', prop: 'goodsQuantity', required: true },
        { label: '含税单价(元)', prop: 'taxUnitPrice', required: true },
        { label: '税额(元)', prop: 'taxAmount' },
        { label: '不含税金额(元)', prop: 'excludingTaxTotalAmount' },
        { label: '含税金额(元)', prop: 'includingTaxTotalAmount' },
        { label: '操作', prop: 'operation' }
      ],
      contractNumberArr: [],
      userArr: [],
      headers: {
        token: localStorage.getItem('logintoken')
      },
      listdata: [{ goodsUnit: '5' }],
      formData: {
        taxRate: '13'
      },
      options: [],
      pageTitle: '采购订单',
      uploadData: [
        {
          materialType: '01',
          status: 1,
          documentName: '购销合同',
          documentType: 'pdf/jpg/png/jpeg，限制10M'
        },
        {
          materialType: '02',
          status: 1,
          documentName: '提货单',
          documentType: 'pdf/jpg/png/jpeg，限制10M'
        },
        {
          materialType: '03',
          status: 1,
          documentName: '点价确认单',
          documentType: 'pdf/jpg/png/jpeg，限制10M'
        },
        {
          materialType: '04',
          status: 1,
          documentName: '发票',
          documentType: 'pdf/jpg/png/jpeg，限制10M'
        },
        {
          materialType: '05',
          status: 1,
          documentName: `付款凭证`,
          documentType: 'pdf/jpg/png/jpeg，限制10M'
        }
      ],
      uploadNeedFile: {
        row: {},
        index: 0
      },
      pageType: 1, // 1是新增采购订单 2是编辑采购订单  3是新增销售订单 4是编辑销售订单
      dateOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  watch: {
    '$route.path': {
      handler(newVal) {
        switch (newVal) {
          case '/transactionData/addPurchasing':
            this.pageType = 1
            break
          case '/transactionData/editPurchasing':
            this.pageType = 2
            break
          case '/transactionData/addMarket':
            this.pageType = 3
            this.uploadData[4].documentName = '收款凭证'
            break
          case '/transactionData/editMarket':
            this.pageType = 4
            this.uploadData[4].documentName = '收款凭证'
            break
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // 提交按钮  请完善商品明细
    submit() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          if (this.formData.purchaseCmpId === this.formData.saleCmpId) {
            this.$message.error('采购方跟销售方不能是同一家公司')
            return
          }
          const flag = this.listdata.some(item => !item.goodsName || !item.firstGoodsCode || !item.goodsQuantity || !item.goodsUnit || !item.taxUnitPrice)
          if (flag) {
            this.$message.error('请完善商品信息')
            return
          }
          const params = {
            baseReq: { ...this.formData },
            billReqList: [...this.uploadData],
            exchangeType: [1, 2].includes(this.pageType) ? '01' : '02',
            goodsDetailList: [...this.listdata]
          }
          const api = [2, 4].includes(this.pageType) ? updateExchangeInfo : addExchangeInfo
          api(params, () => {
            this.$message.success('保存成功')
            // 跳转页面
            this.$router.push({
              path: [2, 1].includes(this.pageType) ? '/transactionData/purchasingManagement' : '/transactionData/marketManagement'
            })
          })
          return
        }
        this.$message.error('请完善必填项')
      })
    },
    // 判断填写的是否有相同的项
    judgmentIdentical(row, index) {
      // 判断四要素是否相等  相等就清空当前项 goodsBrand  goodsName  goodsUnit  goodsMaterial
      let flag = false; let number = 0
      this.listdata.forEach((item, num) => {
        // 要是在index不相等的情况下   四要素相等就是一样的
        if (index !== num && item.goodsBrand === row.goodsBrand && item.goodsName === row.goodsName && item.goodsUnit === row.goodsUnit && item.goodsMaterial === row.goodsMaterial) {
          flag = true
          number = num
        }
      })
      if (flag) {
        this.$message.error(`添加的此项与列表上得第${number + 1}项相同`)
        this.$set(this.listdata, index, {})
      }
      this.computationRule()
      this.$forceUpdate()
    },
    // 计算数据
    computationRule(row) {
      let totalAmount = 0
      let totalQuantity = 0
      this.listdata.forEach(item => {
        totalAmount += Number(roundUpToInteger((Number(item.goodsQuantity || 0) * Number(item.taxUnitPrice || 0))))
        totalQuantity += Number(item.goodsQuantity || 0)
      })
      this.formData.totalAmount = roundUpToInteger(totalAmount) // 总价钱
      this.formData.totalQuantity = roundUpToInteger(totalQuantity, 3)// 总重量
      this.formData.totalAmountUppercase = totalAmount > 0 ? numberDX2(roundUpToInteger(totalAmount)) : ''// 大写字母
      if (row && row.constructor === Object) {
        const money = Number(row.goodsQuantity || 0) * Number(row.taxUnitPrice || 0)
        const hans = money / (1 + (Number(this.formData.taxRate || 0) / 100))
        this.$set(row, 'taxAmount', roundUpToInteger(Number(roundUpToInteger(money)) - Number(roundUpToInteger(hans)))) // 税额
        this.$set(row, 'excludingTaxTotalAmount', roundUpToInteger(hans)) // 不含税金额(元)
        this.$set(row, 'includingTaxTotalAmount', roundUpToInteger(money))// 含税金额(元)
      }
      this.$forceUpdate()
    },
    // 取消按钮
    back() {
      this.$router.go(-1)
    },
    // 获取销售方
    getsellerName(e) {
      this.userArr.forEach(val => {
        if (val.id === e) this.formData.saleCmpName = val.cmpName
      })
    },
    // 获取销售方
    getProcuringName(e) {
      this.userArr.forEach(val => {
        if (val.id === e) this.formData.purchaseCmpName = val.cmpName
      })
    },
    // 获取品种名
    getGoods(row) {
      this.goodsArr.forEach(val => {
        if (val.code === row.firstGoodsCode) this.$set(row, 'firstGoodsName', val.goodsName)
      })
    },
    // 当点击上传的时候拿取点击的是第几条
    getUploadContent(row, index) {
      this.uploadNeedFile.row = { ...row }
      this.uploadNeedFile.index = index
      this.uploadData[index].productDocumentId = row.id
    },
    // 点击下载文件按钮
    downloadAllFile(row) {
      // 传入URL 名字 类型
      downloadFile2(this.$fileUrl + row.materialUrl, row.fileName, row.type)
    },
    // 上传文件之前
    beforeUpload(file, r) {
      const arr = file.name.split('.')
      const fileFormat = 'pdf/jpg/png/jpeg'
      if (!(fileFormat.indexOf(arr[arr.length - 1]) >= 0)) {
        this.$message.error(`上传的格式只能是pdf/jpg/png/jpeg`)
        return false
      }
      if (file.size / 1024 / 1024 > 10) {
        this.$message.error(`文件大小应小于10M`)
        return false
      }
      // 把文件状态改为上传中
      this.uploadData[this.uploadNeedFile.index].status = 2
      this.uploadData[this.uploadNeedFile.index].fileName = file.name
      this.uploadData[this.uploadNeedFile.index].type = arr[1]
    },
    // 点击取消文件
    deleteUploadFile(row, index) {
      this.uploadData[index].status = 1
      this.uploadData[index].fileName = ''
      this.uploadData[index].type = ''
      this.uploadData[index].materialUrl = ''
    },
    handleAvatarError(res) {
      this.$message.error('上传失败，请稍后重试')
    },
    // 上传文件成功
    handleAvatarSuccess(res) {
      if (res.code === 200) {
        this.uploadData[this.uploadNeedFile.index].materialUrl = res.data
        this.uploadData[this.uploadNeedFile.index].status = 3
        return
      }
      this.uploadData[this.uploadNeedFile.index].status = 4
      this.$message.error(res.message)
      if (res.code === '1000' || res.code === 1000) {
        this.$router.push({
          path: '/login'
        })
      }
    },
    // 点击删除的按钮
    deleteList(index) {
      this.listdata.splice(index, 1)
      this.computationRule()
    },
    // 点击新增的按钮
    addList() {
      if (this.listdata.length >= 50) {
        this.$message.error('最高可添加五十条数据')
        return
      }
      this.listdata.push({
        goodsUnit: '5'
      })
    },
    init() {
      // 获取省市
      getSelectAreaTree('', (res) => {
        res.data.forEach((item) => {
          if (item.children?.length > 0) {
            item.children.forEach((val) => {
              val.children = null
            })
          }
        })
        this.options = [...res.data]
      })
      // 获取产品类型
      getChildGoodsList('', res => {
        this.goodsArr = [...res.data]
      })
      // 获取销售方跟采购方
      getTransactionCmpInfo({ contractCode: '' }, res => {
        this.userArr = [...res.data]
      })
      // 获取详情
      if (this.$route.query.id) {
        detailExchangeInfo(this.$route.query.id, res => {
          res.data.cityArr = [res.data.provinceCode, res.data.cityCode]
          this.formData = { ...res.data }
          if (this.formData.goodsDetailList) this.listdata = [...this.formData.goodsDetailList]
          // 材料清单
          this.uploadData.forEach(val => {
            this.formData.materialBillList.forEach(item => {
              if (val.materialType === item.materialType && item.materialUrl) {
                val.status = 3
                val.materialUrl = item.materialUrl
                val.fileName = item.fileName
                if (val.materialUrl) {
                  const type = val.materialUrl.split('.')
                  this.$set(val, 'type', type.length >= 2 ? type[1] : '')
                }
              }
            })
          })
        })
      }
      this.getContractNumber()
    },
    // 获取合同编号
    getContractNumber(value) {
      getExchangeDetail({ cmpName: value || '' }, res => {
        this.contractNumberArr = [...res.data]
      })
    },
    handleChange(arr) {
      if (arr.length === 2) {
        const nameArr = this.$refs['cascader'].getCheckedNodes()[0].pathLabels
        this.formData.provinceCode = arr[0]
        this.formData.cityCode = arr[1]
        this.formData.provinceName = nameArr[0]
        this.formData.cityName = nameArr[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-purchasing-page-box {
  background-color: #fff;

  .commodity-details-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .button-assembly-box {
      text-align: center;
      padding: 24px 0;
    }
    .statistics-box {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      border: 1px solid #ededed;
      border-top: 0;
      .item{
        flex: 0 0 50%;
        display: flex;
        align-items: center;
      }
      .el-input {
        flex: 1;
        padding-left: 8px;
        /deep/.el-input__inner {
          border: 0;
        }
      }

      span {
        flex: 0 0 156px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-right: 1px solid #ededed;
        color: #909399;
        font-size: 14px;
      }
      p {
        flex: 1;
        height: 42px;
        line-height: 42px;
        padding-left: 8px;
        border-right: 1px solid #ededed;
      }
    }
    .subtitle {
      padding: 24px 0;
    }
    .delete-icon {
      font-size: 28px;
      color: $pointClearColor;
      cursor: pointer;
      margin-left: 8px;
    }
    .add-icon {
      font-size: 28px;
      color: $dominantColor;
      cursor: pointer;
    }
  }
  .havenUploaded {
    display: flex;
    align-items: center;
    /deep/.el-upload {
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 14px;
      color: #333;
      margin-right: 12px;
      width: 180px;
    }
    img {
      height: 24px;
      width: 24px;
      margin-right: 12px;
    }
    .el-button {
      margin-right: 12px;
    }
    a {
      margin-right: 12px;
    }
  }
  .page-title {
    text-align: center;
    font-size: 20px;
    padding: 24px 0;
    font-weight: 500;
  }
  .el-form {
    /deep/.el-form-item {
      .el-form-item__content {
        width: auto;
      }
    }
  }
}
</style>
